import { useEffect, useState } from 'react';
import React from 'react';

import helpers from './helpers'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const { forwardRef, useRef, useImperativeHandle } = React;

const PortfolioDateGrid = React.forwardRef ((props, ref) => {

  const gridRef = React.useRef();

  //const [dateKey, setDateKey] = useState();

  const columnDefs = [
    /*{headerName : 'Portfolio', style:{'align':'center'},
    children: [*/
    { field: 'date_key', hide: true },
    { field: 'date_str', headerName: 'Open Date', cellStyle: {'text-align': 'left'}, width:'100px'},
    { field: 'price_date_str',  headerName: 'Close/MTM Date', cellStyle: {'text-align': 'left'}, width:'100px'},
    {
      headerName: 'Portfolio Chg',
      field: 'portfolio_change', sortable: true,flex: 1, cellStyle: {'text-align': 'right'},
      cellRenderer : params => {
      if ( params.data.portfolio_change >= 0.0 )
      {
        return ( <div><span>
             <span style={{color: "rgb(152,195,121)"}}> { helpers.valueFormatter ( params.data.portfolio_change ) } % </span>
             </span></div> );
      } else {
         return ( <div><span>
              <span style={{color: "rgb(224,108,118)"}}> { helpers.valueFormatter (params.data.portfolio_change)  } % </span>
              </span></div> );
      }
    },
    },
    {
      headerName: 'S&P(SPY)',
      field: 'benchmark_change', sortable: true,flex: 1, cellStyle: {'text-align': 'right'},
      cellRenderer : params => {
        if ( params.data.benchmark_change >= 0.0 )
        {
          return ( <div><span>
           <span style={{color: "rgb(152,195,121)"}}> { helpers.valueFormatter ( params.data.benchmark_change ) } % </span>
           </span></div> );
        } else {
           return ( <div><span>
            <span style={{color: "rgb(224,108,118)"}}> { helpers.valueFormatter ( params.data.benchmark_change ) } % </span>
            </span></div> );
        }
      }
    },
  //]}
  ];


  useEffect(() => {
        if ( gridRef.current.api != undefined )
        {
            gridRef.current.api.setRowData(props.portfolioDates);
            props.termChangeCallback("Medium");
        }
  }, [props.portfolioDates]);

  useEffect(() => {
        //console.log("-------->>> Date Key :" + props.portfolioDatesData);
        if ( gridRef.current.api != undefined )
        {
          gridRef.current.api.forEachNode(function(node) {

            if ( props.dateKey == undefined && node.rowIndex == 20 )
            {
              node.setSelected(true);
              props.reportDateChangeCallback( node.data );
              return;
            } else if ( props.dateKey != undefined)
            {
              var row_date_key = node.data['date_key']
              var date_key_data = props.portfolioDatesData[row_date_key]

              if ( date_key_data != undefined )
              {
                node.data = date_key_data;

                if ( node.data['date_key'] == props.dateKey['date_key'] )
                {
                  node.setSelected(true);
                  props.reportDateChangeCallback( node.data );
                }
              } else {
                console.log("Date Key is Undefined");
              }
            }
          });
        }
  }, [props.portfolioDatesData, props.dateKey]);


  const gridOptions = {

      rowSelection: 'single',

      onRowClicked: event => {
        console.log('row', event);
        props.reportDateChangeCallback(event.data)
      },

      onRowSelected: event => {
        console.log("RowSelected!!!!!" + event.data.industry_id );
      },

      onCellClicked: event => {
        console.log("Indstury Pick :" + event.data.industry_id);
      },

      onColumnResized: event => console.log('A column was resized'),

      // CALLBACKS
      //getRowHeight: (params) => 25,

      onGridReady: event =>
      {
        //console.log('Grid Ready Event');
        //gridRef.current.sizeColumnsToFit();
          /*gridRef.current.api.setRowData(props.portfolioDatesData);
          Report_date_change();*/
      },
  }


  const Term_data_change = ( term_change ) =>
  {
      console.log("+++++ Term data change :" + term_change);
      props.termChangeCallback(term_change);
      //setDateKey(term_change)

      // topStocksPanelRef.current.populate_top_stock_data(row_data.current, business_date.current, 30, industry_key);
  }

  /*const Report_date_change = () =>
  {

          if ( gridRef.current.api === undefined )
            return;

          gridRef.current.api.refreshCells();
          var defaultSortModel = [
            { colId: 'Change', sort: 'desc', sortIndex: 0 }
          ];
          gridRef.current.columnApi.applyColumnState({ state: defaultSortModel });

          gridRef.current.api.setRowData(props.portfolioDatesData);
        }*/

   return (

     <div>
       <div className="ag-theme-balham-dark" style={{height:"80vh"}}>
           <AgGridReact columnDefs={columnDefs} gridOptions={gridOptions} ref={gridRef}>
           </AgGridReact>
       </div>
       </div>
     )
  });

export default PortfolioDateGrid;
