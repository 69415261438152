import { SegmentedControl } from 'segmented-control'
import { useEffect, useState } from 'react';
import React from 'react';


const MarketCapControl = React.forwardRef ((props, ref) => {

  const ChangeSegment = ( newValue ) =>
  {
    console.log("Test" +  newValue );

    props.termChangeCallback(newValue);
  }


  return (
      <div>
      <SegmentedControl name="oneDisabled"
          style={{ color: 'rgb(115 201 145)',
            fontSize:"14px"}}
          options={[
                    /* { label: "L_ETF", value: "L_ETF"},
                    { label: "ETF", value: "ETF"},*/
                    { label: "Micro", value: "Micro"},
                    { label: "Small", value: "Small" },
                    { label: "Medium", value: "Medium", default: true },
                    { label: "Large", value: "Large"},
                    { label: "COMP", value: "COMP"}
                  ]}
                  setValue={newValue => ChangeSegment(newValue)}
                  />
      </div>
    )

  });


export default MarketCapControl;
