import React from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';

const { forwardRef, useRef, useImperativeHandle } = React;

const RevenueAndIncomeChart = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
  });


  useEffect(() => {

          console.log(props.stockData);

          if ( props.stockData == undefined)
            return;

          var selected_symbol = props.stockData['selected_symbol']
          var market_data = {}

          try {
            market_data = props.stockData.data[selected_symbol]['market_data'][0]['stats']['financials']['quarterly']
          }  catch (error) {
            console.error(error);
          }


          var benchmark_labels = [];
          var revenue = [];
          var income = [];
          for (var prop in market_data) {

                try {
                  benchmark_labels.push(prop)
                  revenue.push(market_data[prop]['income_statement']['revenues']['value'])
                  income.push(market_data[prop]['income_statement']['net_income_loss']['value'])
                }  catch (error) {
                  console.error(error);
                }

          }


          const char_data = {
            type: 'bar',
            labels: benchmark_labels,
            datasets: [
              {
                label: "Revenue",
                backgroundColor: "rgba(152,195,121,0.75)",
                borderColor: "rgba(152,195,121,1)",
                borderWidth: 0,
                //stack: 1,
                hoverBackgroundColor: "rgba(152,195,121,0.75)",
                hoverBorderColor: "rgba(152,195,121,0.75)",
                data: revenue
              },

              {
                label: "Income",
                backgroundColor: "rgba(224,108,118,0.75)",
                borderColor: "rgba(224,108,118,0.75)",
                borderWidth: 0,
                //stack: 1,
                hoverBackgroundColor: "rgba(224,108,118,0.75)",
                hoverBorderColor: "rgba(224,108,118,0.75)",
                data: income
              }
            ]
          };


          setData(char_data);

          var start_date = props.stockData.start_date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
          var end_date = props.stockData.end_date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');

          const options = {
              responsive: true,
              interaction: {
                mode: 'index',
                intersect: false,
              },
              stacked: false,
              plugins: {
                title: {
                  display: true,
                  text: start_date + ' - ' + end_date
                }
              },
              scales: {
                y: {
                  type: 'linear',
                  display: true,
                  position: 'left',
                },
                y1: {
                  type: 'linear',
                  display: true,
                  position: 'right',

                  // grid line settings
                  grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                  },
                },
              }
            }

            setOptions(options)


  }, [ props.stockData ]);


  return <Bar options={options} data={data} ref={chartRef}/>;
});

export default RevenueAndIncomeChart;
