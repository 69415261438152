import { SegmentedControl } from 'segmented-control'
import { useEffect, useState } from 'react';
import React from 'react';


const DescriptionPanel = React.forwardRef ((props, ref) => {

  const [stockDescription, setStockDescription] = useState([""]);
  const [stockName, setStockName] = useState([""]);

  useEffect(() => {

      if ( !props.stockData )
        return;

        var selected_symbol = props.stockData.selected_symbol
        var marketData = props.stockData.data[selected_symbol]


        try {

          setStockDescription(marketData.market_data[0].static_data['Company Description']);
          setStockName(marketData.market_data[0].static_data['name']);

      } catch (error) {
        console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
    }, [props.stockData]);

  return (

      <div className="App-Stock-Description" style={{paddingTop:"10px"}}>
        {
          stockDescription === null ? "No description" : stockDescription
        }
        </div>
    )

  });


export default DescriptionPanel;
