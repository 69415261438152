import { useEffect, useState } from 'react';
import React from 'react';

import BasicStockChart from './BasicStockChart';
import NewsReviewPanel from './NewsReviewPanel';


import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const { forwardRef, useRef, useImperativeHandle } = React;

const StockPickPanel = React.forwardRef ((props, ref) => {

  const stockPickGridRef = React.useRef();
  const chartRef = React.useRef();

  const [stockGridData, setStockGridData] = useState([]);
  const [stockName, setStockName] = useState([""]);
  const [idKey, setIdKey] = useState(0);
  const [stockDescription, setStockDescription] = useState([""]);
  const [stockPrices, setStockPrices] = useState({"prices":{}, "ticker":""});
  const [marketData, setMarketData] = useState(null);
  const [externalLinks, setExternalLinks] = useState(null);
  const [updatedExternalLinks, setUpdatedExternalLinks] = useState(null);


  const columnDefs = [
    { field: 'key', flex: 1 },
    { field: 'value',
      cellRenderer : params => {
             // put the value in bold
             if ( params.data.key === "Last Price")
             {
               if ( params === undefined || params.value === undefined  )
                 return "";

               if ( params.value.price_change >= 0.0 )
               {
                 return ( <div><span style={{fontWeight: 'bold'}}><span>{params.value.real_time_price}</span>
                      <span style={{color: "rgb(152,195,121)"}}>  +{params.value.price_change} +({params.value.change_percent}%) </span>
                      </span></div> );
               } else {
                  return ( <div><span style={{fontWeight: 'bold'}}><span>{params.value.real_time_price}</span>
                       <span style={{color: "rgb(224,108,118)"}}> {params.value.price_change} ({params.value.change_percent}%) </span>
                       </span></div> );
               }
             } else if ( params.data.key === "Market Cap.") {

               var new_value = "";

               if ( params.value > 1000000000 )
               {
                 new_value = (params.value/1000000000).toFixed(3).toString() + "B";
               } else if ( params.value > 1000000 )
               {
                 new_value = (params.value/1000000).toFixed(3).toString() + "M";
               } else {
                 new_value = (params.value/1000).toFixed(3).toString() + "K";
               }

               return new_value;

             } else if ( params.data.key === "YTD. Change" )
             {
               if ( params.value >= 0.0 )
               {
                 return ( <div>
                          <span style={{color: "rgb(152,195,121)"}}>  +{params.value} </span>
                          </div> );
               } else {
                  return ( <div>
                       <span style={{color: "rgb(224,108,118)"}}> {params.value}  </span>
                       </div> );
               }
             }

            return <div>{params.value}</div>;
         } },
  ];

  const columnExternalLinksDefs = [
    { field: 'link', width: 100, flex: 1,
    cellRenderer : params => {
      return <a href={params.value} target="_blank">{params.data.display_name}</a>;
    }},
  ];

  const gridOptions = {

      rowSelection: 'single',
      headerHeight: 0,

      // CALLBACKS
      getRowHeight: (params) => 25
  }

  const gridOptionsExternalLinks = {

      rowSelection: 'single',
      headerHeight: 0,

      // CALLBACKS
      getRowHeight: (params) => 25
  }


  const Stock_pick = (rowData, id_key ) =>
  {
    var price_reset = {"data":{"chart":{"stock_prices":{}, "ticker":""},"market_data":{}}};
    setMarketData(price_reset);
    setStockPrices(price_reset);
    setUpdatedExternalLinks(null);

    var ref_data = rowData.stock_descriptions;
    var stock_ref_data = ref_data[id_key][0];
    setStockName(stock_ref_data.name);
    setStockDescription(stock_ref_data.description);
    setIdKey(id_key);

    setExternalLinks(rowData.external_links)
  }

  //const url = "http://127.0.0.1:8000";
  //const url = "http://159.203.147.248";
  //const url = "https://www.itopstocks.com/";



useEffect(() => {

    if ( !props.stockData || props.externalLinks == undefined )
      return;


    var selected_symbol = props.stockData.selected_symbol
    var marketData = props.stockData.data[selected_symbol]

    var stock_market_data = [];

    var external_links_with_symbol = [];
    props.externalLinks.external_links.forEach((item, i) => {
      var exernal_link = {};
      exernal_link['link_id'] = item.link_id;
      exernal_link['link_type_id'] = item.link_type_id;
      exernal_link['display_name'] = item.display_name;
      var updated_link = item.link.replace('%s', selected_symbol);

      if ( item.link_type_id === 1 )
      {
        if ( marketData.market_data.primaryExchange !== 'NASDAQ')
        {
            updated_link = updated_link.replace('%e', 'NYSE');
        } else {
            updated_link = updated_link.replace('%e', 'NASDAQ');
        }
      }

      exernal_link['link'] = updated_link;


      external_links_with_symbol.push(exernal_link);
    });

      setUpdatedExternalLinks(external_links_with_symbol);

      try {

        setStockDescription(marketData.market_data[0].static_data['Company Description']);
        setStockName(marketData.market_data[0].static_data['name']);

    } catch (error) {
      console.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }


    try {

      var market_data_in = marketData.market_data;

      if ( market_data_in == undefined || market_data_in.length == 0 )
      {
        setStockGridData(null);
        setUpdatedExternalLinks(external_links_with_symbol);

        setStockGridData(stock_market_data);

        setStockPrices(marketData);
        props.doneLoadingCallback();

        return;
      }

      var market_data = market_data_in[0].market_data
      var stats = market_data_in[0].stats

      var real_time_price = market_data.day.vw;
      var price_change = parseFloat(market_data.todaysChange).toFixed(2);
      var change_percent = parseFloat(market_data.todaysChangePerc).toFixed(2);

      var price_string = {};
      price_string['key'] = 'Last Price';
      var price_val = {};
      price_val["real_time_price"] = real_time_price;
      price_val["price_change"] = price_change;
      price_val["change_percent"] = change_percent;

      price_string['value'] = price_val;
      stock_market_data.push(price_string);

    } catch (error) {
      console.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }


    try {
    var prev_close = market_data.prevDay.c;
    var market_open = market_data.day.o;

    var open_close_string = {};
    open_close_string['key'] = 'Prev.Close - Open';
    open_close_string['value'] = prev_close + " - " + market_open;
    stock_market_data.push(open_close_string);
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }

    try {
    var ytd_change = marketData.market_data.ytdChange*100;

    var ytd_string = {};
    ytd_string['key'] = 'YTD. Change';
    ytd_string['value'] = (((stats.last_close - stats.first_close)/stats.first_close)*100.0).toFixed(2);
    stock_market_data.push(ytd_string);
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }


  try {

    var week_52_low = stats.min;
    var week_52_high = stats.max;

    var week_52_range_string = {};
    week_52_range_string['key'] = '52 Week Range';
    week_52_range_string['value'] = week_52_low + " - " + week_52_high;;
    stock_market_data.push(week_52_range_string);
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }

/*
  try {
    var pe_ratio = marketData.market_data.peRatio;

    var pe_string = {};
    pe_string['key'] = 'EPS';
    pe_string['value'] = parseFloat(stats.eps).toFixed(2);
    stock_market_data.push(pe_string);
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }
  */

/*
  try {
    //var market_cap = marketData.market_data.marketCap;

    var mc_string = {};
    mc_string['key'] = 'Market Cap.';
    mc_string['value'] = stats.market_cap;
    stock_market_data.push(mc_string);
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }
  */

  try {
    var avg_total_volume = market_data.day.v;

    var avg_total_volume_string = {};
    avg_total_volume_string['key'] = 'AvgTotalVol.';
    avg_total_volume_string['value'] = avg_total_volume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
    stock_market_data.push(avg_total_volume_string);
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }

  try {
    var revenues = 0;

    const report_date = Object.keys(stats['financials']['ttm'])[0];

    var revenues = stats['financials']['ttm'][report_date]['income_statement']['revenues']['value']
    var revenues_string = {};
    revenues_string['key'] = 'Revenues';
    revenues_string['value'] = revenues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
    stock_market_data.push(revenues_string);

    var net_income_loss = stats['financials']['ttm'][report_date]['income_statement']['net_income_loss']['value']
    var net_income_loss_string = {};
    net_income_loss_string['key'] = 'Net Income';
    net_income_loss_string['value'] = net_income_loss.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
    stock_market_data.push(net_income_loss_string);

    var mc_string = {}
    mc_string['key'] = 'Market Cap.';
    mc_string['value'] = stats['details']['market_cap'] ;
    stock_market_data.push(mc_string);

    var eps_string = {}
    eps_string['key'] = 'EPS';
    eps_string['value'] = parseFloat(stats['financials']['ttm'][report_date]['income_statement']['basic_earnings_per_share']['value']).toFixed(2)
    stock_market_data.push(eps_string);

  } catch (error) {
    console.error(error);
  }


  try {

    var timestamp = market_data.updated/1000000;

    var timestamp_string = {};
    timestamp_string['key'] = 'Last Update';

    var newDate = new Date();
    newDate.setTime(timestamp);
    var dateString = newDate.toUTCString();

    timestamp_string['value'] = dateString;
    stock_market_data.push(timestamp_string);
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }


    setStockGridData(stock_market_data);

    //setStockName("TEST")

    //setStockPrices(marketData);

    //props.doneLoadingCallback();

}, [props.stockData, props.externalLinks]);



   return (
        <div className="App-Header-Stock">
            <div className="App-Stock-Name" style={{padding:"5px", textAlign:'center', color:'#61dafb'}}>
              {stockName}
            </div>
            <div className="App-Row-StockPick">
              <div className="App-Stock-Stats">
                <div className="ag-theme-balham-dark" style={{height:"39vh"}}>
                    <AgGridReact rowData={stockGridData} columnDefs={columnDefs} gridOptions={gridOptions} ref={stockPickGridRef}/>
                </div>
              </div>
              </div>
            </div>
     )
  });

export default StockPickPanel;
