import { useEffect, useState } from 'react';
import React from 'react';

import helpers from './helpers'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const { forwardRef, useRef, useImperativeHandle } = React;

const NewsPanel = React.forwardRef ((props, ref) => {

  const gridRef = React.useRef();

  const [newsOut, setNewsOut] = useState();

  const columnDefs = [
    /*{headerName : 'Portfolio', style:{'align':'center'},
    children: [*/
    { field: 'published_utc', headerName: 'Time', width: 155},
    { field: 'sentiment',  headerName: 'Sentiment', width: 75,

    cellRenderer : params => {

    if ( params.data.sentiment == 'positive')
    {
      return ( <div><span>
           <span style={{color: "rgb(152,195,121)"}}> {params.data.sentiment} </span>
           </span></div> );
    } else if (params.data.sentiment == 'negative') {
       return ( <div><span>
            <span style={{color: "rgb(224,108,118)"}}> {params.data.sentiment} </span>
            </span></div> );
    } else {
      return ( <div><span>
           <span> {params.data.sentiment} </span>
           </span></div> );
    }

    },


    },
    { field: 'title',  headerName: 'Title', width: 400,
    cellRenderer : params => {
       return <span>
        <a style={{color: 'white', textDecoration: 'none'}} href={params.data.article_url} target="_blank"><div>{params.value}</div></a></span>;
    }},
    { field: 'publisher', headerName: 'Publisher'},
    { field: 'author',  headerName: 'Author', width: 100},
  ];


  useEffect(() => {

        if ( gridRef.current.api != undefined && props.news != undefined )
        {
            console.log(props.news)

            var news_out = []
            var symbol = props.news['symbol']

            if ( props.news['news_list'] == undefined )
            {
              setNewsOut(news_out)
              return;
            }

            props.news['news_list'].forEach(
              (element) => {

                var element_out = {}

                element_out['published_utc'] = element['published_utc'].replaceAll(/[a-zA-Z]/g, ' ')
                element_out['title'] = element['title']
                element_out['author'] = element['author']

                if ( element['publisher'] !== undefined && element['publisher']['name'] !== undefined )
                {
                  element_out['publisher'] = element['publisher']['name']

                  element_out['title'] = element['title']
                  element_out['article_url'] = element['article_url']
                  element_out['sentiment'] = '';

                  if ( element['insights'] !== undefined )
                  {
                    element['insights'].forEach((item, i) => {
                      if (item['ticker'] !== undefined && item['ticker'] == symbol && item['sentiment'] !== undefined )
                      {
                        element_out['sentiment'] = item['sentiment']
                      }
                    });
                  }

                  news_out.push(element_out)

                  console.log(element)
                }
              }
            );

            setNewsOut(news_out)
        }
  }, [props.news]);


  const gridOptions = {

      rowSelection: 'single',

      onRowClicked: event => {
        console.log('row', event);
        //props.onNewsSelect(event.data.article_url)
      },

      onRowSelected: event => {
        console.log("RowSelected!!!!!" + event.data.industry_id );
      },

      onCellClicked: event => {
        console.log("Indstury Pick :" + event.data.industry_id);
      },

      onColumnResized: event => console.log('A column was resized'),

      onGridReady: event =>
      {
      },
  }


   return (

     <div>
       <div className="ag-theme-balham-dark" style={{height:"35vh"}}>
           <AgGridReact rowData={newsOut} columnDefs={columnDefs} gridOptions={gridOptions} ref={gridRef}>
           </AgGridReact>
       </div>
       </div>
     )
  });

export default NewsPanel;
