import { useEffect, useState } from 'react';
import React from 'react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import { Container, Row, Col } from 'react-bootstrap/';

import AdSense from 'react-adsense';

import helpers from './helpers'

import TopStocksPanel from './TopStocksPanel'

const { forwardRef, useRef, useImperativeHandle } = React;

const IndustryPanel = React.forwardRef ((props, ref) => {

  const gridIndustryRef = React.useRef();
  const gridStocksRef = React.useRef();

  const [industryName, setIndustryName] = useState([""]);

  const columnDefs = [
    { headerName: 'Company Size', field: 'company_size', width: 200, hide: true   },
    { headerName: 'Price Date', field: 'price_date', width: 200, hide: true   },
    { headerName: 'Industry Name', field: 'industry_name', width: 200  },
    { headerName: 'AvgChg(%)',
      field: 'avg_delta', sortable: true,flex: 1, width: 40, cellStyle: {'text-align': 'right'},
      cellRenderer : params => {
      if ( params.data.avg_delta >= 0.0 )
      {
        return ( <div><span>
             <span style={{color: "rgb(152,195,121)"}}> { helpers.valueFormatter (params.data.avg_delta ) } % </span>
             </span></div> );
      } else {
         return ( <div><span>
              <span style={{color: "rgb(224,108,118)"}}> { helpers.valueFormatter (params.data.avg_delta ) } % </span>
              </span></div> );
      }
    }

  },
];


  const columnDefsStockPick = [
    { headerName: 'Symbol', field: 'symbol', width: 75, pinned: 'left' },
    { headerName: 'Open Price', field: 'open_price', width: 75, pinned: 'left', cellStyle: {'text-align': 'right'} },
    { headerName: 'MTM Price', field: 'market_price', width: 75, pinned: 'left', cellStyle: {'text-align': 'right'} },
    { headerName: 'Price Chg(%)', field: 'price_chg', width: 100, pinned: 'left', sortable: true,flex: 1, cellStyle: {'text-align': 'right'},
    cellRenderer : params => {
    if ( params.data.price_chg >= 0.0 )
    {
      return ( <div><span>
           <span style={{color: "rgb(152,195,121)"}}> { helpers.valueFormatter (params.data.price_chg ) } % </span>
           </span></div> );
    } else {
       return ( <div><span>
            <span style={{color: "rgb(224,108,118)"}}> { helpers.valueFormatter (params.data.price_chg ) } % </span>
            </span></div> );
    }
  } },
    { headerName: 'Open Date', field: 'open_date_str', width: 100 },
    { headerName: 'MTM Date', field: 'price_date_str', width: 100 },
    { field: 'mean', width: 75 },
    { field: 'sharp', width: 75 },
    { field: 'median', width: 75 },
    { field: 'volume', width: 75 },
    { field: 'std_dev', width: 75 },
    { field: 'rel_sharp', width: 75 },
    { field: 'price_delta', width: 75 },
    { field: 'rel_std_dev', width: 75 },
    { field: 'latest_price', width: 75 },
    { field: 'time_series_start', width: 100 },
    { field: 'time_series_end', width: 100 },
    { field: 'time_series_len', width: 75 },
  ];

  const gridOptions = {

      rowSelection: 'single',

      onRowClicked: event => {
        console.log('row', event);

        props.industryPickChangeCallback( event.data );
      },

      onCellClicked: event => {
        // setBusinessDate(event.data.Date);
        console.log('cell', event);
      },

      onRowDataChanged: event => {
        var defaultSortModel = [
          { colId: 'change', sort: 'desc', sortIndex: 0 }
        ];
        gridIndustryRef.current.columnApi.applyColumnState({ state: defaultSortModel });


        gridIndustryRef.current.api.forEachNode(function(node) {

          if ( node.rowIndex == 0 )
          {
            node.setSelected(true);
            props.industryPickChangeCallback( node.data );
            return;
          }
        })
      },

      onColumnResized: event => console.log('A column was resized'),

      // CALLBACKS
      getRowHeight: (params) => 25
  }

  const gridOptionsStockPick = {

      rowSelection: 'single',

      onRowClicked: event => {
        console.log('row', event);
        props.stockPickChangeCallback( event.data );
      },

      onCellClicked: event => {
        // setBusinessDate(event.data.Date);
        console.log('cell', event);
      },

      onColumnResized: event => console.log('A column was resized'),

      onRowDataChanged: event => {
        var defaultSortModel = [
          { colId: 'change', sort: 'desc', sortIndex: 0 }
        ];
        gridStocksRef.current.columnApi.applyColumnState({ state: defaultSortModel });


        gridStocksRef.current.api.forEachNode(function(node) {

          if ( node.rowIndex == 0 )
          {
            node.setSelected(true);
            props.stockPickChangeCallback( node.data );
            return;
          }
        })
      },

      // CALLBACKS
      getRowHeight: (params) => 25
  }

   useEffect(() => {
     if ( gridIndustryRef.current.api != undefined && props.portfolioIndustryData != undefined )
     {
           var row_data_array = [];
           props.portfolioIndustryData.forEach((item, i) => { row_data_array.push(item)});
           gridIndustryRef.current.api.setRowData(row_data_array);
      }
    }, [props.portfolioIndustryData]);

    useEffect(() => {
      if ( gridIndustryRef.current.api != undefined && props.industryTickerList != undefined && props.dateKey != undefined )
      {
            var row_data_array = [];
            props.industryTickerList.forEach((item, i) => {

              var position_details = item['position_details']
              var instrument_details = item['instrument_details']


              var item_dict = {}
              item_dict['symbol'] = instrument_details['symbol']
              item_dict['open_price'] = position_details['open_price']
              item_dict['market_price'] = position_details['market_price']
              item_dict['price_chg'] = Math.round(((position_details['market_price'] - position_details['open_price'])/position_details['open_price'])*100.0)


              item_dict['open_date'] = position_details['open_date']
              item_dict['price_date'] = position_details['price_date']
              item_dict['open_date_str'] = helpers.formatDate (position_details['open_date'])
              item_dict['price_date_str'] = helpers.formatDate (position_details['price_date'])

              item_dict['mean']=position_details['stats']['mean']
              item_dict['sharp']= position_details['stats']['sharp']
              item_dict['median']= position_details['stats']['median']
              item_dict['volume']= position_details['stats']['volume']
              item_dict['std_dev']= position_details['stats']['std_dev']
              item_dict['rel_sharp']= position_details['stats']['rel_sharp']
              item_dict['price_delta']= position_details['stats']['price_delta']
              item_dict['rel_std_dev']= position_details['stats']['rel_std_dev']
              item_dict['latest_price']= position_details['stats']['latest_price']
              item_dict['time_series_start']= helpers.formatDate (position_details['stats']['time_series_start'])
              item_dict['time_series_end']= helpers.formatDate (position_details['stats']['time_series_end'])
              item_dict['time_series_len']= position_details['stats']['time_series_len']


              row_data_array.push(item_dict)
            });
            gridStocksRef.current.api.setRowData(row_data_array);
       }
     }, [props.industryTickerList]);


   return (
        <Row>
            <div className="ag-theme-balham-dark" style={{width:'35%', height:"40vh"}}>
              <AgGridReact columnDefs={columnDefs} gridOptions={gridOptions} ref={gridIndustryRef}>
              </AgGridReact>
            </div>
            <div className="ag-theme-balham-dark" style={{width:'65%', height:"40vh"}}>
              <AgGridReact columnDefs={columnDefsStockPick} gridOptions={gridOptionsStockPick} ref={gridStocksRef}>
              </AgGridReact>
            </div>
        </Row>
     )
  });

export default IndustryPanel;
