import { SegmentedControl } from 'segmented-control'
import { useEffect, useState } from 'react';
import React from 'react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const ExternalLinksPanel = React.forwardRef ((props, ref) => {

  const [externalLinks, setExternalLinks] = useState(null);
  const [updatedExternalLinks, setUpdatedExternalLinks] = useState(null);

  const columnExternalLinksDefs = [
    { field: 'link', width: 100, flex: 1,
    cellRenderer : params => {
      return <a href={params.value} target="_blank">{params.data.display_name}</a>;
    }},
  ];

  const gridOptionsExternalLinks = {

      rowSelection: 'single',
      headerHeight: 0,

      // CALLBACKS
      getRowHeight: (params) => 25
  }


  useEffect(() => {

      if ( !props.stockData || props.externalLinks == undefined )
        return;


      var selected_symbol = props.stockData.selected_symbol
      var marketData = props.stockData.data[selected_symbol]

      var stock_market_data = [];

      var external_links_with_symbol = [];
      props.externalLinks.external_links.forEach((item, i) => {
        var exernal_link = {};
        exernal_link['link_id'] = item.link_id;
        exernal_link['link_type_id'] = item.link_type_id;
        exernal_link['display_name'] = item.display_name;
        var updated_link = item.link.replace('%s', selected_symbol);

        if ( item.link_type_id === 1 )
        {
          if ( marketData.market_data.primaryExchange !== 'NASDAQ')
          {
              updated_link = updated_link.replace('%e', 'NYSE');
          } else {
              updated_link = updated_link.replace('%e', 'NASDAQ');
          }
        }

        exernal_link['link'] = updated_link;


        external_links_with_symbol.push(exernal_link);
      });

        setUpdatedExternalLinks(external_links_with_symbol);

        /*try {

          setStockDescription(marketData.market_data[0].static_data['Company Description']);
          setStockName(marketData.market_data[0].static_data['name']);

      } catch (error) {
        console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }*/
    }, [props.stockData, props.externalLinks]);

  return (
      <div className="ag-theme-balham-dark" style={{height:"12vh"}}>
          <AgGridReact rowData={updatedExternalLinks} gridOptions={gridOptionsExternalLinks} columnDefs={columnExternalLinksDefs}/>
      </div>
    )

  });


export default ExternalLinksPanel;
