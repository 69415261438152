

const helpers = {

  amountFormatter : function(param)
  {
    return param;
  },

  valueFormatter : function(params)
  {
    if ( parseFloat(params) == undefined )
      return '';

    if ( parseFloat(params) < 0 )
      return '(' + helpers.amountFormatter(Math.abs(params)) + ')'
    else
      return helpers.amountFormatter(params);
  },

  formatDate : function(dateInt)
  {
    if ( dateInt == undefined )
      return ''

    var dateString = dateInt.toString()
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);

    return `${year}-${month}-${day}`;
  }

}

export default helpers;
